import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  Typography,
  useTheme,
  Theme,
  Grid,
  Box
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useRouter } from 'next/router';
import { InfoTwoTone, SettingsTwoTone } from '@mui/icons-material';
import { useReactiveVar } from '@apollo/client';
import Link from '../atoms/TifLink';

import {
  useGetAppBarItemQuery,
  useGetActiveBrandLazyQuery,
  useSideBarSwitcherQuery,
  useGetIsEditingBrandQuery,
  // useGetLoggedInUserVarQuery,
  useGetActiveOrganizationQuery
} from '../../generated/graphql';
import { loggedInUserVAR, selectedAppBarItemVAR } from '../../util/cache';
import TifMainBar from './TifMainbar';
import TifCatwalkBar from './TifCatwalkBar';
import TifBrandBar from './TifBrandBar';
import AvatarImage from './Avatar';

const drawerWidth = 240;
const avaSize = 120;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    position: 'relative'
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    overflowX: 'hidden',
    width: drawerWidth,
    height: 'auto',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    height: 'auto',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: 'flex',
    borderColor: 'none',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  accountLogo: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  accountItem: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',

    padding: 0
  },
  backButton: {
    padding: theme.spacing(0.5)
  },
  hidden: {
    visibility: 'hidden'
  },
  avatarOpen: {
    height: avaSize,
    width: avaSize
  },
  primary: {
    margin: theme.spacing(1),
    textAlign: 'center'
  },

  gridVertical: {
    height: '100%',
    flex: 1,
    marginBottom: 10,
    padding: 0,
    marginLeft: 0,
    marginRight: 0
  },
  listBottom: {
    top: 'auto',
    bottom: 0,
    // position: 'absolute',
    width: '100%'
  },
  rootSelected: {
    borderLeftWidth: 2,
    borderLeftColor: theme.palette.secondary.main,
    borderLeftStyle: 'solid',
    margin: 0,
    padding: 0
  }
}));

interface AppBarToDisplayProps {
  collection: string | string[];
}

export function AppBarToDisplay({ collection }: AppBarToDisplayProps) {
  return collection ? <TifCatwalkBar /> : <TifBrandBar />;
}

export default function SideBar() {
  const classes = useStyles();
  const theme = useTheme();
  const router = useRouter();

  const routerString = router.asPath;
  const routerArray = [
    'brands',
    'collections',
    'organizations',
    'shortfilms',
    'films',
    'series',
    'seasons',
    'episodes'
  ];
  const {
    brands,
    collection,
    organization,
    shortfilm,
    film,
    serie,
    seasons,
    episode,
    fashionweeks
  } = router.query;

  const storePathValues = () => {
    if (
      brands &&
      collection &&
      routerString.substring(routerString.lastIndexOf('/')) === '/details'
    ) {
      router.push(`/collections/${brands}/content`);
    }
    if (
      brands &&
      !collection &&
      routerString.substring(routerString.lastIndexOf('/')) === '/details'
    ) {
      router.push(`/collections/${brands}/content`);
    }
    if (
      brands &&
      !collection &&
      routerString.substring(routerString.lastIndexOf('/')) === '/content'
    ) {
      router.push('/collections');
    }
    if (organization) {
      router.push('/organizations');
    }
    if (shortfilm) {
      router.push('/shortfilms');
    }
    if (film) {
      router.push('/films');
    }
    if (fashionweeks) {
      router.push('/fashionweeks');
    }
    if (
      serie &&
      seasons &&
      episode &&
      routerString.substring(routerString.lastIndexOf('/')) === '/details'
    ) {
      router.push(`/series/${serie}/${seasons}/episodes`);
    }
    if (
      serie &&
      seasons &&
      !episode &&
      routerString.substring(routerString.lastIndexOf('/')) === '/details'
    ) {
      router.push(`/series/${serie}/${seasons}/episodes`);
    }
    if (routerString.substring(routerString.lastIndexOf('/')) === '/episodes') {
      router.push(`/series/${serie}/seasons`);
    }
    if (
      serie &&
      !seasons &&
      !episode &&
      routerString.substring(routerString.lastIndexOf('/')) === '/details'
    ) {
      router.push(`/series/${serie}/seasons`);
    }
    if (routerString.substring(routerString.lastIndexOf('/')) === '/seasons') {
      router.push('/series');
    }
  };

  const [getActiveBrand, { data: selectedBrandData }] = useGetActiveBrandLazyQuery();

  const loggedUser = useReactiveVar(loggedInUserVAR);
  const { data: isOrg } = useGetIsEditingBrandQuery({
    skip: !loggedUser?.sub,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { id: loggedUser?.sub },

    onCompleted: (data) => {
      if (router?.asPath.split('/')[1] === 'collections')
        getActiveBrand({
          variables: { id: data?.studio_settings[0]?.is_editing_brand }
        });
    }
  });

  const { data: selectedOrganizationData } = useGetActiveOrganizationQuery({
    variables: { id: isOrg?.studio_settings[0]?.is_working_as_org },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });
  const isActiveBrand =
    selectedOrganizationData?.organizations[0]?.brands?.filter(
      (it) => it?.id === isOrg?.studio_settings[0]?.is_editing_brand
    ).length > 0;

  const avatarData = () => {
    if (router?.asPath.split('/')[1] === 'collections' && isActiveBrand) {
      return {
        name: selectedBrandData?.brands[0]?.name,
        avatar: selectedBrandData?.brands[0]?.active_thumbnail_poster?.url
      };
    }
    if (router?.asPath.split('/')[1] === 'collections' && !isActiveBrand) {
      return {
        name: '',
        avatar: null
      };
    }
    return {
      name: selectedOrganizationData?.organizations[0]?.name,
      avatar: selectedOrganizationData?.organizations[0]?.avatar_relation?.url
    };
  };

  useGetAppBarItemQuery();

  const { data: drawerStatus } = useSideBarSwitcherQuery(); // status of drawer

  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerStatus?.toggleDrawer,
          [classes.drawerClose]: !drawerStatus?.toggleDrawer
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerStatus?.toggleDrawer,
            [classes.drawerClose]: !drawerStatus?.toggleDrawer
          })
        }}
      >
        <div className={classes.toolbar} />
        <Divider />

        <List className={classes.toolbar}>
          <ListItem
            className={clsx(classes.accountLogo, {
              [classes.accountItem]: drawerStatus?.toggleDrawer
            })}
          >
            <ListItem
              button
              onClick={storePathValues}
              className={
                !routerArray?.includes(router?.asPath.substring(1))
                  ? classes.backButton
                  : classes.hidden
              }
            >
              <ListItemIcon style={{ padding: theme.spacing(1, 0) }}>
                <ArrowBackIcon />
              </ListItemIcon>
              <Typography style={{ paddingLeft: theme.spacing(3) }}>Back</Typography>
            </ListItem>

            <ListItem
              button
              component={Link}
              href="/"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <ListItemAvatar className={drawerStatus?.toggleDrawer ? classes.avatarOpen : ''}>
                <AvatarImage src={avatarData()?.avatar} status={drawerStatus?.toggleDrawer} />
              </ListItemAvatar>
            </ListItem>
            <ListItemText
              className={clsx(classes.primary, { [classes.hide]: !drawerStatus?.toggleDrawer })}
              primary={avatarData()?.name}
              style={{ color: 'white' }}
            />
          </ListItem>
        </List>

        <Grid
          className={classes.gridVertical}
          direction="column"
          container
          justifyContent="space-between"
        >
          <Grid item>
            {router?.asPath?.split('/').length <= 2 ||
            router?.asPath?.split('/')[1] === 'organizations' ? (
              <TifMainBar />
            ) : (
              <AppBarToDisplay collection={collection} />
            )}
          </Grid>
          <Grid item>
            <List className={classes.listBottom}>
              <Box className={selectedAppBarItemVAR() === 'settings' ? classes.rootSelected : ''}>
                <Divider />
                <ListItem
                  button
                  // component={Link}
                  // naked
                  selected={selectedAppBarItemVAR() === 'settings'}
                  onClick={() => selectedAppBarItemVAR('settings')}
                  href="#"
                  // label="Setings"
                  // aria-label="Setings"
                >
                  <ListItemIcon>
                    <SettingsTwoTone />
                  </ListItemIcon>
                  <ListItemText primary="Settings" style={{ color: 'white' }} />
                </ListItem>
                <Divider />
              </Box>
              <Box className={selectedAppBarItemVAR() === 'info' ? classes.rootSelected : ''}>
                <ListItem
                  button
                  // component={Link}
                  selected={selectedAppBarItemVAR() === 'info'}
                  // naked
                  href="#"
                  onClick={() => selectedAppBarItemVAR('info')}
                  // label="About"
                  // aria-label="About"
                >
                  <ListItemIcon>
                    <InfoTwoTone />
                  </ListItemIcon>
                  <ListItemText primary="About" style={{ color: theme.palette.text.primary }} />
                </ListItem>
                <Divider />
              </Box>
            </List>
          </Grid>
        </Grid>
      </Drawer>
    </div>
  );
}
