import React from 'react';

import { makeStyles } from '@mui/styles';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  useTheme
} from '@mui/material';
import { CreateTwoTone, GTranslateTwoTone, BarChartTwoTone } from '@mui/icons-material';
import { useRouter } from 'next/router';
import { activeBrandSelectedVAR, selectedAppBarItemVAR, rowDataVAR } from '../../util/cache';

const useStyles = makeStyles((theme: Theme) => ({
  rootSelected: {
    overflowX: 'hidden',
    borderLeftWidth: 2,
    borderLeftColor: theme.palette.secondary.main,
    borderLeftStyle: 'solid',
    margin: 0,
    padding: 0
  }
}));

function TifCatwalkBar() {
  const classes = useStyles();
  const router = useRouter();
  const theme = useTheme();

  return (
    <List>
      <Divider />
      <Box className={selectedAppBarItemVAR() === 'details' ? classes.rootSelected : ''}>
        <ListItem
          button
          selected={selectedAppBarItemVAR() === 'details'}
          onClick={() => {
            selectedAppBarItemVAR('details');
            router.push(
              `/collections/${activeBrandSelectedVAR()?.slug}/content/${rowDataVAR().title}/details`
            );
          }}
        >
          <ListItemIcon>
            <CreateTwoTone />
          </ListItemIcon>
          <ListItemText primary="Details" style={{ color: theme.palette.text.primary }} />
        </ListItem>
      </Box>

      <Divider />
      <Box className={selectedAppBarItemVAR() === 'translations' ? classes.rootSelected : ''}>
        <ListItem button selected={selectedAppBarItemVAR() === 'translations'}>
          <ListItemIcon>
            <GTranslateTwoTone />
          </ListItemIcon>
          <ListItemText primary="Translations" style={{ color: theme.palette.text.primary }} />
        </ListItem>
      </Box>

      <Divider />
      <Box className={selectedAppBarItemVAR() === 'analytics' ? classes.rootSelected : ''}>
        <ListItem button selected={selectedAppBarItemVAR() === 'analytics'}>
          <ListItemIcon>
            <BarChartTwoTone />
          </ListItemIcon>
          <ListItemText primary="Analytics" style={{ color: theme.palette.text.primary }} />
        </ListItem>
      </Box>
      <Divider />
    </List>
  );
}

export default TifCatwalkBar;
