import React from 'react';
import Image from 'next/image';
import { styled } from '@mui/material/styles';

const AvatarLarge = styled(Image)({
  height: '100%',
  width: '100%',
  objectFit: 'cover'
});
const AvatarSmall = styled(Image)({
  borderRadius: '55px',
  textAlign: 'center'
});

interface AvatarImageProps {
  src: string;
  status: boolean;
}

function AvatarImage({ src, status }: AvatarImageProps) {
  return (
    <div style={{ width: '100%', padding: '4px' }}>
      {status ? (
        <AvatarLarge
          src={!src ? '/static/icons/SVG/TIF-Icon.svg' : process.env.TIF_IMAGE_CDN + src}
          alt="Avatar"
          height={100}
          width={100}
          objectFit="cover"
          layout="responsive"
          priority
        />
      ) : (
        <AvatarSmall
          src={!src ? '/static/icons/SVG/TIF-Icon.svg' : process.env.TIF_IMAGE_CDN + src}
          alt="Avatar"
          height={100}
          width={100}
          objectFit="cover"
          layout="responsive"
          priority
        />
      )}
    </div>
  );
}

export default AvatarImage;
