/* eslint-disable react/jsx-props-no-spreading */
import { SvgIcon } from '@mui/material';

function TifMenuIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M24,16.84l-2.47,2.49H20.11l-7.38-5.58a3.1,3.1,0,0,1-.6-.6l-.05-.09s0-.06-.08-.06-.08.06-.08.06l-.05.09a3.1,3.1,0,0,1-.6.6L3.89,19.33H2.47L0,16.84V7L2.47,4.51H3.89l7.38,5.58a3.1,3.1,0,0,1,.6.6.56.56,0,0,0,.05.08s0,.07.08.07.08-.07.08-.07a.56.56,0,0,0,.05-.08,3.1,3.1,0,0,1,.6-.6l7.38-5.58h1.42L24,7Z" />
    </SvgIcon>
  );
}

export default TifMenuIcon;
