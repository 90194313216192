/* eslint-disable react/jsx-props-no-spreading */
import { SvgIcon } from '@mui/material';

function TifDressIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <path
        fillOpacity="0.5"
        d="M15.5,11.72A3,3,0,0,1,15,7.92a2.58,2.58,0,0,0,.49-1.21,1.57,1.57,0,0,0-2.87-.86.68.68,0,0,1-1.15,0,1.57,1.57,0,0,0-2.87.86A2.46,2.46,0,0,0,9,7.94a3,3,0,0,1-.56,3.79C6,14,4.39,18.34,4.25,23.17h15.5C19.6,18.33,18,14,15.5,11.72Z"
      />
      <path d="M16.27,10.63a1.52,1.52,0,0,1-.3-2,3.64,3.64,0,0,0,.67-1.88,2.84,2.84,0,0,0-1.76-2.62V.71a.67.67,0,1,0-1.34,0V4A2.79,2.79,0,0,0,12,4.62,2.79,2.79,0,0,0,10.46,4V.71A.66.66,0,0,0,9.8,0a.67.67,0,0,0-.67.67V4.17A2.83,2.83,0,0,0,7.36,6.79,3.67,3.67,0,0,0,8,8.68a1.54,1.54,0,0,1-.3,2C4.94,13.16,3.21,18,3.21,23.29a.67.67,0,0,0,.67.67H20.12a.67.67,0,0,0,.67-.67C20.79,18,19.06,13.15,16.27,10.63Zm-11.72,12c.13-4.65,1.67-8.83,4.07-11A2.88,2.88,0,0,0,9.16,8a2.41,2.41,0,0,1-.47-1.19,1.5,1.5,0,0,1,1.5-1.5A1.52,1.52,0,0,1,11.45,6a.66.66,0,0,0,1.11,0,1.5,1.5,0,0,1,2.75.82A2.38,2.38,0,0,1,14.84,8a2.86,2.86,0,0,0,.53,3.65c2.41,2.18,4,6.36,4.08,11Z" />
    </SvgIcon>
  );
}

export default TifDressIcon;
