import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import Image from 'next/image';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    height: '100%',
    width: '100%'
  },
  blur: {
    backdropFilter: 'blur(5px) brightness(0.4)'
  }
}));

interface TifBackgroundProps {
  children?: React.ReactNode;
}

function TifBackground({ children }: TifBackgroundProps) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Image src="/static/images/634324.jpg" alt="background" layout="fill" objectFit="cover" />
      <Box className={classes.blur}>{children}</Box>
    </Box>
  );
}

export default TifBackground;
