import React from 'react';

import { makeStyles } from '@mui/styles';
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import {
  VideoLibrary,
  GTranslateTwoTone,
  BarChartTwoTone,
  CreateTwoTone
} from '@mui/icons-material';
// import Link from '../atoms/TifLink';
import { useRouter } from 'next/router';
import { selectedAppBarItemVAR } from '../../util/cache';

const useStyles = makeStyles(() => ({
  rootSelected: {
    overflowX: 'hidden',
    borderLeftWidth: 2,
    borderLeftColor: 'red',
    borderLeftStyle: 'solid',
    margin: 0,
    padding: 0
  }
}));

function TifBrandBar() {
  const classes = useStyles();
  const router = useRouter();
  const { brands, serie, seasons, film, shortfilm, fashionweeks } = router.query;
  const sidebarContentItem = ['content', 'seasons', 'episodes'];

  return (
    <List>
      <Divider />
      <Box
        className={sidebarContentItem.includes(selectedAppBarItemVAR()) ? classes.rootSelected : ''}
      >
        <ListItem
          button
          selected={selectedAppBarItemVAR() === 'content'}
          onClick={() => {
            selectedAppBarItemVAR('content');
            if (brands) {
              router.push(`/collections/${router?.query?.brands}/content`);
            }
            if (fashionweeks) {
              router.push(`/fashionweeks/${router?.query?.fashionweeks}/content`);
            }
            if (shortfilm) {
              router.push('/shortfilms');
            }
            if (film) {
              router.push('/films');
            }
            if (serie) {
              router.push(`/series/${serie}/seasons`);
            }
            if (seasons) {
              router.push(`/series/${router?.query?.serie}/${router?.query?.seasons}/episodes`);
            }
          }}
        >
          <ListItemIcon>
            <VideoLibrary />
          </ListItemIcon>
          <ListItemText primary="Content" style={{ color: 'white' }} />
        </ListItem>
      </Box>

      <Divider />
      <Box className={selectedAppBarItemVAR() === 'details' ? classes.rootSelected : ''}>
        <ListItem
          button
          selected={selectedAppBarItemVAR() === 'details'}
          onClick={() => {
            selectedAppBarItemVAR('details');
            if (brands) {
              router.push(`/collections/${brands}/details`);
            }
            if (fashionweeks) {
              router.push(`/fashionweeks/${fashionweeks}/details`);
            } else if (seasons) {
              router.push(`/series/${serie}/${seasons}/details`);
            } else if (serie && !seasons) {
              router.push(`/series/${serie}/details`);
            }
          }}
        >
          <ListItemIcon>
            <CreateTwoTone />
          </ListItemIcon>
          <ListItemText primary="Details" style={{ color: 'white' }} />
        </ListItem>
      </Box>

      <Divider />
      <Box className={selectedAppBarItemVAR() === 'translations' ? classes.rootSelected : ''}>
        <ListItem button selected={selectedAppBarItemVAR() === 'translations'}>
          <ListItemIcon>
            <GTranslateTwoTone />
          </ListItemIcon>
          <ListItemText primary="Translations" style={{ color: 'white' }} />
        </ListItem>
      </Box>

      <Divider />
      <Box className={selectedAppBarItemVAR() === 'analytics' ? classes.rootSelected : ''}>
        <ListItem button selected={selectedAppBarItemVAR() === 'analytics'}>
          <ListItemIcon>
            <BarChartTwoTone />
          </ListItemIcon>
          <ListItemText primary="Analytics" style={{ color: 'white' }} />
        </ListItem>
      </Box>
      <Divider />
    </List>
  );
}

export default TifBrandBar;
