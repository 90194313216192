/* eslint-disable no-unused-vars */
import React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Box, Theme } from '@mui/material';
import { useSideBarSwitcherQuery } from '../../generated/graphql';
import PrimarySearchAppBar from '../TifAppBar/TopBar';
import SideBar from '../TifAppBar/SideBar';
import TifBackground from './TifBackground';

interface LayoutProps {
  children: React.ReactNode;
  className?: {};
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: '100vh'
    // height: `calc(100vh - ${appbarHeight}px)`
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    overflowY: 'hidden',
    overflowX: 'hidden'
  },
  contentShift: {
    padding: theme.spacing(2)
  }
}));

function Layout({ children }: LayoutProps) {
  const classes = useStyles();
  const { data: drawerStatus } = useSideBarSwitcherQuery();
  return (
    <TifBackground>
      <PrimarySearchAppBar />

      <Box className={classes.root}>
        <SideBar />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: drawerStatus?.toggleDrawer
          })}
        >
          {children}
        </main>
      </Box>
    </TifBackground>
  );
}

export default Layout;
