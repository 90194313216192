import React from 'react';
import NextHead from 'next/head';

import TifTheme from '../../theme/TifTheme';

//
// Troubleshoot before launch.
// 1. all OG metadata
// 2. Facebook app id
//

interface TifHeadProps {
  title?: string;
  description?: string;
  url: string;
  ogImage?: string;
  ogVideo?: string;
}

function TifHead(props: TifHeadProps) {
  // const TifTheme = useTheme() // hook is to be used inside func component it gets defaulttheme but we have tiftheme

  return (
    <NextHead>
      <title>{props.title || 'This Is Fashion'}</title>
      <meta charSet="UTF-8" />
      <meta name="description" content={props.description} />
      <link rel="icon" href="/static/favicon.ico" />
      <link rel="manifest" href="/manifest.json" />
      {/* PWA primary color */}
      <meta name="theme-color" content={TifTheme.palette.background.default} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" sizes="192x192" href="/static/tif-android-icon-192x192.png" />
      <link rel="apple-touch-icon" href="/static/tif-apple-icon-180x180.png" />
      <link rel="mask-icon" href="/static/TIF-Icon.svg" color="#ffffff" />
      <meta property="og:url" content={props.url} />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:type" content="website" />
      <meta property="fb:app_id" content="1891924987711976" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={props.ogImage} />
      <meta property="og:image" content={props.ogImage} />
      <meta property="og:video" content={props.ogVideo} />
      <meta property="og:image:width" content="1920" />
      <meta property="og:image:height" content="1080" />
    </NextHead>
  );
}

export default TifHead;
