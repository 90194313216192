import React from 'react';

import { makeStyles } from '@mui/styles';
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { mainMenuItems } from 'src/util/constants';
import Link from '../atoms/TifLink';
import { selectedAppBarItemVAR } from '../../util/cache';

const useStyles = makeStyles(() => ({
  rootSelected: {
    overflowX: 'hidden',
    borderLeftWidth: 2,
    borderLeftColor: 'red',
    borderLeftStyle: 'solid',
    margin: 0,
    padding: 0
  }
}));

function TifMainBar() {
  const classes = useStyles();

  return (
    <List>
      <Divider />
      {mainMenuItems.map(({ type, title, icon }) => (
        <div key={type}>
          <Box className={selectedAppBarItemVAR() === type ? classes.rootSelected : ''}>
            <ListItem
              button
              component={Link}
              selected={selectedAppBarItemVAR() === type}
              onClick={() => selectedAppBarItemVAR(type)}
              href={`/${type}`}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText
                primary={title}
                style={{ color: 'white', textTransform: 'capitalize' }}
              />
            </ListItem>
          </Box>
          <Divider />
        </div>
      ))}
    </List>
  );
}

export default TifMainBar;
