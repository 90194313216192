import React from 'react';

import { Dashboard, MovieFilter } from '@mui/icons-material';
import TifDressIcon from 'src/components/atoms/icons/TifDressIcon';
import { TFashionWeekStatus } from './types';

export const mainMenuItems = [
  { type: 'dashboard', title: 'Dashboard', icon: <Dashboard /> },
  { type: 'organizations', title: 'Organizations', icon: <Dashboard /> },
  { type: 'collections', title: 'Collections', icon: <TifDressIcon /> },
  { type: 'shortfilms', title: 'Short films', icon: <MovieFilter /> },
  { type: 'films', title: 'Films', icon: <MovieFilter /> },
  { type: 'series', title: 'Series', icon: <MovieFilter /> },
  { type: 'fashionweeks', title: 'Fashion weeks', icon: <Dashboard /> }
];

export const appbarHeight = '64px';

export const fashionWeekStatus: TFashionWeekStatus = {
  accepted: 'accepted',
  rejected: 'rejected',
  pending: 'pending'
};
